import { Box, Skeleton, Typography } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, FreeMode, Thumbs, EffectFade } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BorderRight } from "@mui/icons-material";


export default function Posts({ posts, width, loading, isMobile }) {
    
    const [selected, setSelected] = useState(0);
    const delay = 6000;
    const timeoutRef = useRef(null);
    const navigate = useNavigate();

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
        () =>
            setSelected((prevIndex) =>
                prevIndex === posts.length - 1 ? 0 : prevIndex + 1
            ),
        delay
        );

        return () => {
            resetTimeout();
        };
    }, [selected]);
    
	return (        
            <Box height={'100%'} position={'relative'}>
                <AnimatePresence>
                {
                    loading ?

                    <motion.div
                        className="box"
                        initial={{ opacity: 0, scale: 1, x:-200 }}
                        animate={{ opacity: 1, scale: 1, x: 0 }}
                        transition={{
                            duration: 0.8,
                            delay: 0,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}
                    >
                        <Box style={{ border: "1px solid #EEEEEC" }}>
                            <Skeleton height={466} variant="rectangular" animation="wave" sx={{ bgcolor: 'grey.600', border: 'none' }}/>
                        </Box>
                    </motion.div>
                    :
                    <motion.div initial={false} animate={{ opacity: 1 }} >
                    {posts.map((post, index) => {
                        return (
                            
                                
                                <Box
                                    style={{
                                        backgroundImage: `url(`+post.image+`)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'contain',
                                        height: "466px",
                                        backgroundColor: 'white',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        opacity: (selected == index ? 1 : 0),
                                        zIndex: (selected == index ? 1 : 0),
                                        transition: ".2s",
                                        cursor: 'pointer',
                                        transition: '.5s'
                                    }}
                                    key={index}
                                    onClick={() => { navigate('/noticia/' + post?.slug) }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: '#ffffff',
                                            position: 'absolute',
                                            bottom: 0,
                                            width: '100%',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <Box padding={'20px'}>
                                            <Typography variant="h3" color={'#575757'} fontFamily={'Montserrat'}>
                                                {post.title}
                                            </Typography>
                                            <Typography variant="body1" color={'black'} fontFamily={'Montserrat'}>
                                                {post.description?.substring(0, 150) + "..."}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                        )
                    
                    })}
                    

                            <Box
                                style={{
                                    position: 'absolute',
                                    width: '20px',
                                    height: '100%',
                                    left: '-30px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    overflow: 'hidden'
                                }}
                            >
                                {posts.map((post, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: selected == index ? 'white' : '#00000080',
                                                marginBottom: '10px',
                                                borderRadius: '100%',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {setSelected(index)}}
                                        ></Box>
                                        )
                                    })}

                            </Box>
                        
                    </motion.div>
                }
                </AnimatePresence>
            </Box>
            

        
	);
}
