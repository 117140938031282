import { Box, Link, Typography } from "@mui/material";

import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
export default function XXSS({ social, isMobile }) {
    const [marginRightBox, setMarginRightBox] = useState(-250);
    const [marginRightIcon, setMarginRightIcon] = useState(40);

    function onSocial() {
        setMarginRightBox(-150);
        setMarginRightIcon(10);
    }
    function outSocial() {
        setMarginRightBox(-250);
        setMarginRightIcon(40);
    }
    if (isMobile) {
        return (
            <Box 
                style={{ 
                    zIndex: 3,
                    transition: '.2s',
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 30,
                    marginBottom: 30
                }}
            >
                <Link href={social?.facebook} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', padding: '10px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', width: 30, height: 30}}>
                    <FacebookIcon sx={{width: 25, height: 25, transition: '.3s'}}/>
                </Link>
                <Link href={social?.twitter} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', padding: '10px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', width: 30, height: 30}}>
                    <XIcon sx={{width: 25, height: 25, transition: '.3s'}}/>
                </Link>
                <Link href={social?.youtube} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', padding: '10px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', width: 30, height: 30}}>
                    <YouTubeIcon sx={{width: 25, height: 25, transition: '.3s'}}/>
                </Link>
                <Link href={social?.instagram} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', padding: '10px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', width: 30, height: 30}}>
                    <InstagramIcon sx={{width: 25, height: 25, transition: '.3s'}}/>
                </Link>
                <Link href={social?.linkedin} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', padding: '10px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', width: 30, height: 30}}>
                    <LinkedInIcon sx={{width: 25, height: 25, transition: '.3s'}}/>
                </Link>
            </Box>
        )
    } else {
        return (
            
            <Box 
                style={{ 
                    zIndex: 3,
                    position: "fixed",
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    marginRight: marginRightBox+'px',
                    transition: '.2s',
                    width: 300
                }}
                onMouseEnter={onSocial}
                onMouseLeave={outSocial}
            >
                <Link href={social?.facebook} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', padding: '10px', paddingRight: '20px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white'}}>
                    <FacebookIcon sx={{width: 25, height: 25, marginRight: marginRightIcon+'px', transition: '.3s'}}/>
                    <Typography variant="body1" color={"#000"} textAlign={'center'} fontSize={'16px'}>Facebook</Typography>
                </Link>
                <Link href={social?.twitter} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', marginTop: '20px', padding: '10px', paddingRight: '20px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white'}}>
                    <XIcon sx={{width: 25, height: 25, marginRight: marginRightIcon+'px', transition: '.3s'}}/>
                    <Typography variant="body1" color={"#000"} textAlign={'center'} fontSize={'16px'}>Twitter</Typography>
                </Link>
                <Link href={social?.youtube} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', marginTop: '20px', padding: '10px', paddingRight: '20px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white'}}>
                    <YouTubeIcon sx={{width: 25, height: 25, marginRight: marginRightIcon+'px', transition: '.3s'}}/>
                    <Typography variant="body1" color={"#000"} textAlign={'center'} fontSize={'16px'}>Youtube</Typography>
                </Link>
                <Link href={social?.instagram} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', marginTop: '20px', padding: '10px', paddingRight: '20px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white'}}>
                    <InstagramIcon sx={{width: 25, height: 25, marginRight: marginRightIcon+'px', transition: '.3s'}}/>
                    <Typography variant="body1" color={"#000"} textAlign={'center'} fontSize={'16px'}>Instagram</Typography>
                </Link>
                <Link href={social?.linkedin} target={"__blank"} display={'flex'} sx={{cursor: 'pointer', marginTop: '20px', padding: '10px', paddingRight: '20px', borderRadius: '100px', textDecoration: 'none', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'white'}}>
                    <LinkedInIcon sx={{width: 25, height: 25, marginRight: marginRightIcon+'px', transition: '.3s'}}/>
                    <Typography variant="body1" color={"#000"} textAlign={'center'} fontSize={'16px'}>LinkedIn</Typography>
                </Link>
            </Box>
        );
    }
}
