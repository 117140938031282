import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useNavigate } from 'react-router';

const Footer = ({isMobile, text_footer, subtext_footer, color, sponsors}) => {

    let navigate = useNavigate();
	const [height, setHeight] = useState(60);

	function onFooter() {
		setHeight(220);
	}

	function outFooter() {
		setHeight(60);
	}

	function onClickSponsor(sponsor) {
		if (sponsor.link !== "") {
			window.location.href = sponsor.link
		}
	}

	if (!isMobile) {
		return (	
			<Box 
				sx={{
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					borderTop: '4px solid ' + color,
					height: height,
					position: 'fixed',
					bottom: 0,
					left: 0,
					width: '100%',
					transition: '.2s',
					zIndex: 2,
					overflow: 'hidden'
				}}
				onMouseEnter={onFooter}
				onMouseLeave={outFooter}
			>
				<Container 
					maxWidth={'md'} 
					width='100%'
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						top: '10px',
						paddingTop: '10px'
					}}
				>
					<Typography variant="footer" mb={'16px'} textAlign={'center'}>{text_footer}</Typography>
					<Grid container sx={{display: 'flex', justifyContent: 'center'}}>
						{sponsors?.map((sponsor, index) => {
							return (
								<Grid item md={3} key={index} display={'flex'} justifyContent={'center'}>
									<img onClick={() => {onClickSponsor(sponsor)}} src={sponsor.logo.url} alt={sponsor.name} style={{objectFit: 'contain', width: '100px', height: '50px', margin: 0}}/>
								</Grid>
							)
						})}
					</Grid>
					<Typography variant="footer" mt={'16px'} textAlign={'center'}>{subtext_footer}</Typography>
				</Container>
			</Box>
		);
	} else {
		return (	
			<Box 
				sx={{
					backgroundColor: 'white',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					borderTop: '4px solid ' + color,
					bottom: 0,
					left: 0,
					width: '100%',
					transition: '.2s',
					zIndex: 2,
					overflow: 'hidden',
				}}
			>
				<Box
					width='90%'
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						top: '10px',
						paddingTop: '10px'
					}}
				>
					<Typography variant="footer" mb={'16px'} textAlign={'center'}>{text_footer}</Typography>
					<Grid container spacing={2} sx={{display: 'flex', justifyContent: 'center'}}>
						{sponsors?.map((sponsor, index) => {
							return (
								<Grid item md={3} key={index} display={'flex'} justifyContent={'center'}>
									<img onClick={() => {onClickSponsor(sponsor)}} src={sponsor.logo.url} alt={sponsor.name} style={{objectFit: 'contain', width: '100px', height: '50px', margin: 0}}/>
								</Grid>
							)
						})}
					</Grid>
					<Typography variant="footer" mt={'16px'} textAlign={'center'}>{subtext_footer}</Typography>
				</Box>
			</Box>
		);
	}


};

export default Footer;
