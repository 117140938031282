import { Drawer, Hidden, IconButton, Link, SvgIcon, Toolbar, Typography } from "@mui/material";
import { Box, color } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
// import LogoIcon from "../../components/elements/LogoIcon";
// import { ReactComponent as LogoIcon } from "../../components/elements/LogoIcon";
import SearchIcon from '@mui/icons-material/Search';
import { motion, AnimatePresence } from "framer-motion";
import '../../App.css';
import Close from "@mui/icons-material/Close";
import IconMenu from "@mui/icons-material/Menu";
import { useLocalStorage } from "usehooks-ts";
const Menu = (props) => {

	const navigate = useNavigate();
	const [selectedMenu, setSelectedMenu] = useLocalStorage('menu', 0);
	const [menu, setMenu] = useState(false);

	useEffect(() => {
        if (selectedMenu != 0 && window.location.pathname === "/") {
            setSelectedMenu(0);
        }
    }, []);
	
	const openMenu = (event) => {
		setMenu((prev) => !prev);
	};

	const closeMenu = () => {
		setMenu(null);
	};

	function onClickItemMenu(item) {
		setSelectedMenu(item.position);
		if (item === 'noticies') {
			navigate('/noticies')
			closeMenu()
		} else if (item) {
			navigate('/'+item.slug)
			closeMenu()
		}
	}
	return (
			<Toolbar
				style={{
					backgroundColor: 'white', 
					height: 70,
					display: 'flex',
					justifyContent: 'space-between',
					paddingLeft: 0
				}}
			>
				<Link href={'/'}>
					<img src="https://adbergueda-web.s3.amazonaws.com/uploads/web/logo/1/ADB___BTF.png" style={{width: 300, height: 32.85, objectFit: 'contain', margin: '10px 0 0 10px', marginLeft: '10px'}}/>
				</Link>
				<Hidden mdDown>
					<Box
						style={{
							flex: 3,
							display: "flex",
							justifyContent: "end",
						}}
					>
						<Box sx={{display: "flex",  alignItems: 'center'}}>
							{props.menu?.map((item, index) => {
								return (
									<Box onClick={() => {onClickItemMenu(item)}} key={index}  p={'10px'} display={'flex'} sx={{textDecoration: 'none', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
										<Typography variant="menu">{item.name}</Typography>
											{(index + 1 == selectedMenu) ? (
												<motion.div
													className="box"
													initial={{ width: 0 }}
													animate={{ width: 50, backgroundColor: item.color, height: 2, display: 'flex', justifyContent: 'center', position: 'relative', bottom: '-3px' }}
												>
												</motion.div>
											) : null}
									</Box>
								)
							})}
							<Box onClick={() => {onClickItemMenu('noticies')}}  p={'10px'} display={'flex'} sx={{textDecoration: 'none', justifyContent: 'center', alignItems: 'center', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}> 
								<Typography variant="menu">{'notícies'}</Typography>
								{('/noticies') === window.location.pathname ? (
									<motion.div
										className="box"
										initial={{ width: 0 }}
										animate={{ width: 50, backgroundColor: '#cccccc', height: 2, display: 'flex', justifyContent: 'center', position: 'relative', bottom: "-3px" }}
									>
									</motion.div>
								) : null}
							</Box>
							<Box mx={2} display={'flex'} sx={{cursor: 'pointer'}} onClick={() => {props.setOpen(!props.open)}}> 
								<SearchIcon htmlColor="#575756"/>
							</Box>
						</Box>
					</Box>
				</Hidden>
				<Hidden mdUp>
					<IconButton style={{ zIndex: 10 }} color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={openMenu}>
						<IconMenu style={{ color: "black" }} />
					</IconButton>
					<Drawer style={{ zIndex: 10000 }} open={menu} onClose={closeMenu} anchor={"right"}>
						<Box justifyContent={"center"} display={"flex"} flexDirection={"column"} pb={2}>
							<Box textAlign={"right"} m={2}>
								<IconButton onClick={closeMenu}>
									<Close />
								</IconButton>
							</Box>
							<Box
								style={{
									flex: 3,
									display: "flex",
									justifyContent: "center",
									flexDirection: "column"
								}}
							>
								<Box sx={{display: "flex",  alignItems: 'flex-start', flexDirection: 'column'}}>
									{props.menu?.map((item, index) => {
										return (
											<Box onClick={() => {onClickItemMenu(item)}} key={index}  p={2} width={300} display={'flex'} sx={{borderTop: '2px solid', textDecoration: 'none', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: ('/'+item.slug) === window.location.pathname ? item.color : 'white'}}> 
												<Typography variant="menu" color={('/'+item.slug) === window.location.pathname ? 'white' : '#333'}>{item.name}</Typography>
												
											</Box>
										)
									})}
									<Box onClick={() => {onClickItemMenu('noticies')}}  p={2} width={300} display={'flex'} sx={{borderTop: '2px solid', textDecoration: 'none', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', backgroundColor: ('/noticies') === window.location.pathname ? '#ccc' : 'white'}}>
										<Typography variant="menu" color={('/noticies') === window.location.pathname ? 'white' : '#333'}>{'notícies'}</Typography>
										
									</Box>
									<Box p={2} width={300} display={'flex'} sx={{borderTop: '2px solid', borderBottom: '2px solid', textDecoration: 'none', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} 
										onClick={() => {
											props.setOpen(!props.open)
											setMenu(false)
										}}
									> 
										<SearchIcon htmlColor="#575756"/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Drawer>
				</Hidden>
			</Toolbar>
	);
}

export default Menu;
