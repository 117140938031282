import React, { useCallback, useEffect, useState } from "react";
import Page from "../../components/layouts/Page";
import { useParams } from "react-router-dom";
import { get } from "../../API/API";
import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import Caixa from "../../components/utils/Caixa";
import { motion, AnimatePresence } from "framer-motion";
import Posts from "../../components/utils/Posts";
import Footer from "../../layouts/public/Footer";
import XXSS from "../../components/utils/XXSS";
import { ColorRing } from "react-loader-spinner";

function Pagina(props) {

	const [loading, setLoading] = useState(false);
    const [pagines, setPagines] = useState([]);
    const [posts, setPosts] = useState([]);
    const [pages, setPages] = useState([]);
    const { name } = useParams();
    const [width, setWidth] = useState(100);
	const div = useCallback((node) => {
		if (node !== null) {
			setWidth(node.getBoundingClientRect().width);
		}
	}, []);

    useEffect(() => {
        const obtenir = async () => {
            const { result, message } = await get("menu/" + name);
            setPagines(result);
        };

        const obtenirPosts = async () => {
            try {
                const { result, message } = await get("posts/" + name);
                setPosts(result);
			} catch (err) {
				console.log(err);
			}
        }

        const obtenirPages = async () => {
            setLoading(true);
            const { result, message } = await get("pages/" + name);
            setPages(result);
        }

        try {
            obtenir();
            obtenirPosts();
            obtenirPages();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
      }, [name]);
	
	function getGridSize(size) {
        switch (size) {
            case "1":
                return 4;
            case "2":
                return 6;
            case "3":
                return 12;
            default: 
                return 4
        }
    }


    
	return (
		<Page title="Agència de desenvolupament del Berguedà" timeout={3000} padding={10}>
			{loading ? 
            <Box
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />
             </Box>
             : 
             <Box 
                display={props.isMobile ? 'block' : 'flex'}
                position={'relative'} 
                height={props.isMobile ? '100%' : '100vh'} 
                width={'100%'} 
                margin={'auto'} 
                sx={{
                    backgroundColor: "white",
                    backgroundImage: `url(`+pages?.image_background?.url+`)`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    overflowY: 'auto'
                }}
            >
                <Container maxWidth='lg' sx={{maxWidth: '1200px', display:props.isMobile ? 'block' : 'flex', alignItems: 'center'}}>
                    <Grid 
                        container 
                        spacing={2}
                        paddingTop={props.isMobile ? '90px' : 0}
                        width={props.isMobile ? '100vw' : '100%'}
                    >
                        <AnimatePresence>
                        {pages?.childs?.length > 0 ? 
                            <>
                                <Grid item md={6} xs={12} sx={{height:'482px'}} order={{ xs: 2, sm: 2, md: 1 }}>
                                    <Box ref={div} width={'100%'} height={'482px'}>
                                                {pages.left_content != "" ? 
                                                    <motion.div
                                                        className="box"
                                                        initial={{ opacity: 0, scale: 1, x:-200 }}
                                                        animate={{ opacity: 1, scale: 1, x: 0 }}
                                                        transition={{
                                                            duration: 0.8,
                                                            delay: 0.1,
                                                            ease: [0, 0.71, 0.2, 1.01]
                                                        }}
                                                    >
                                                        <Card sx={{height: '466px', overflow: 'scroll'}} variant="outlined">
                                                            <CardContent>
                                                                <Typography
                                                                    dangerouslySetInnerHTML={{ __html: pages.left_content }}
                                                                ></Typography>
                                                            </CardContent>
                                                        </Card> 
                                                    </motion.div>
                                                    :
                                                    <Posts posts={posts} width={width} loading={loading}/>
                                                }
                                    </Box>
                                </Grid>
                                <Grid item md={6} xs={12} order={{ xs: 1, sm: 1, md: 2 }}>
                                    <motion.div
                                        className="box"
                                        initial={{ opacity: 0, scale: 0.5 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{
                                            duration: 0.8,
                                            delay: 0.1,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        }}
                                    >
                                        <Grid container spacing={props.isMobile ? 0.1 : 1}>
                                            {pagines?.map((pagina, index) => {
                                                return (
                                                    <Grid key={index} item md={getGridSize(pagina?.size)} xs={getGridSize(pagina?.size)}>
                                                        <Caixa data={pagina} pages={pages} isMobile={props.isMobile}/>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </motion.div>
                                </Grid>
                            </> :
                            <>
                                <Box sx={{marginTop: props.isMobile ? 0 : "90px", marginBottom: props.isMobile ? 0 : "90px", background: 'white', paddingBottom: '100px', marginBottom: '50px'}}>
                                    {pages?.image_header != null ? <Box sx={{width: '100%', height: props.isMobile ? 'auto' : 200, overflow: 'hidden'}}><img src={pages?.image_header} alt="image_header" style={{width: '100%', objectFit: 'cover', margin: 0}}/></Box> : <></>}
                                    {pages?.name != "" ? <Typography variant="h3" fontSize={'24px'} margin={'20px 0px'} padding={'40px 20px 0'} color={'#575757'} fontWeight={700}>{pages.name}</Typography> : <></>}
                                    <Grid container sx={{position: 'relative'}}>
                                        <Grid item md={8} xs={12}>
                                            <Typography
                                                padding={2}
                                                dangerouslySetInnerHTML={{ __html: pages?.content }}
                                            ></Typography>
                                        </Grid>
                                        {pages?.image_right != null ? 
                                            <Grid item md={4} xs={12}>
                                                {props.isMobile ? <></> : <img 
                                                    src={pages.image_right} 
                                                    alt="image_header" 
                                                    style={{
                                                        display: 'block', 
                                                        position: 'absolute',
                                                        right: '50px',
                                                        top: '200px',
                                                        width: '200px',
                                                        height: '200px',
                                                        borderRadius: '50%',
                                                        margin: 0
                                                    }}
                                                />}
                                            </Grid> :
                                            <></>
                                        }
                                    </Grid>
                                </Box>
                            </>
                        }
                        </AnimatePresence>
                    </Grid>
                    <XXSS isMobile={props.isMobile} social={props.web?.social}/>
                </Container>
			    <Footer isMobile={props.isMobile} text_footer={props.web?.text_footer} subtext_footer={props.web?.subtext_footer} color={pages?.main_color} sponsors={props.web?.sponsors}/>
            </Box>}
		</Page>
	);
}

export default Pagina;
